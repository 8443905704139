"use client";

import { ThemeProvider } from "@mui/material/styles";
import { getTheme, CustomAppPalette } from "@patron/utils/theme";
import React, { useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SnackbarProvider } from "notistack";
import { GlobalModalProvider } from "@patron/utils/context";
import { FaroErrorBoundary } from "@grafana/faro-react";
import { FaroWeb, Faro } from "./Faro";
import { FallbackErrorComponent } from "@patron/ui/error-component";

import { ErrorBoundary } from "react-error-boundary";

LicenseInfo.setLicenseKey(
  "39b20c1effd4c09bb8a75446913ddebaTz04MDMzOSxFPTE3MzM4NDI0OTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export const ClientProviders = ({
  children,
  isAnalticsEnabled,
  customPalette,
}: {
  children?: React.ReactNode;
  isAnalticsEnabled?: string;
  customPalette?: CustomAppPalette;
}) => {
  const faroRef = useRef<null | Faro>(null);

  useEffect(() => {
    if (
      !faroRef.current &&
      typeof window !== "undefined" &&
      isAnalticsEnabled === "true"
    ) {
      //@ts-ignore
      faroRef.current = FaroWeb();
    }
  }, [isAnalticsEnabled]);

  const shouldRenderErroBoundry = () => {
    if (isAnalticsEnabled === "false") {
      return (
        <ErrorBoundary
          FallbackComponent={FallbackErrorComponent}
        ></ErrorBoundary>
      );
    } else {
      return (
        <FaroErrorBoundary
          fallback={(error, reset) =>
            FallbackErrorComponent({ error, resetErrorBoundary: reset })
          }
        ></FaroErrorBoundary>
      );
    }
  };
  return (
    <ThemeProvider theme={getTheme(customPalette)}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {shouldRenderErroBoundry()}
        <GlobalModalProvider>
          <CssBaseline />
          {children}
        </GlobalModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
