"use client";

import { ReactNode } from "react";
import React from "react";
import axios from "axios";
import {
  NextIntlClientProvider,
  AbstractIntlMessages,
  IntlErrorCode,
} from "next-intl";

interface Props {
  messages: AbstractIntlMessages;
  locale: string;
  children: ReactNode;
  hermesUrl: string;
  hermesToken: string;
}

export const NextIntlProvider = ({
  messages,
  locale,
  children,
  hermesUrl,
  hermesToken,
}: Props) => {
  const onError = (error: { code: IntlErrorCode }) => {
    if (error.code !== IntlErrorCode.MISSING_MESSAGE) {
      console.error("error in translations: ", error);
    }
  };

  const getMessageFallback = (
    { namespace, key, error }: any,
    fallbackLocale: string
  ) => {
    let namespaceLocal = namespace;
    let keyLocal = key;
    const path = [namespaceLocal, keyLocal]
      .filter((part) => part !== null)
      .join(".");
    if (error.code === IntlErrorCode.MISSING_MESSAGE) {
      console.log("missing message: ", path, fallbackLocale);
      if (
        namespaceLocal === undefined ||
        (namespaceLocal === "" &&
          key !== undefined &&
          key !== "" &&
          key.includes("."))
      ) {
        namespaceLocal = key.split(".", 2)[0];
        keyLocal = key.split(".", 2)[1];
      }
      if (!isNaN(keyLocal)) {
        return keyLocal.toString();
      }
      if (
        process.env.NEXT_PUBLIC_ADD_TRANSLATIONS === "true" &&
        hermesToken &&
        hermesUrl
      ) {
        if (
          namespaceLocal !== undefined &&
          namespaceLocal !== "" &&
          keyLocal !== undefined &&
          keyLocal !== ""
        ) {
          axios
            .post(
              hermesUrl + "/addtranslation",
              { key: keyLocal, namespace: namespaceLocal },
              {
                headers: {
                  authorization: `Bearer ${hermesToken}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                withCredentials: true,
              }
            )
            .catch((FEerror) => {
              console.log("addTranslation failed", FEerror);
            });
        } else {
          console.log("unknown message format: ", path);
        }
      }
      return `${path}`;
    } else {
      console.log("unknown message format: ", path, fallbackLocale);
      return `${path}`;
    }
  };

  return (
    <NextIntlClientProvider
      timeZone={"Europe/Amsterdam"}
      locale={locale}
      messages={messages}
      defaultTranslationValues={{
        i: (text) => <i>{text}</i>,
      }}
      onError={onError}
      getMessageFallback={(props) => getMessageFallback(props, locale)}
    >
      {children}
    </NextIntlClientProvider>
  );
};
